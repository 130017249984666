import React from 'react'
export const susd = {
  path: (
    <>
      <g clip-path="url(#clip0_14079_7258)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM11.9999 20.6666C16.7864 20.6666 20.6666 16.7864 20.6666 11.9999C20.6666 7.21345 16.7864 3.33325 11.9999 3.33325C7.21345 3.33325 3.33325 7.21345 3.33325 11.9999C3.33325 16.7864 7.21345 20.6666 11.9999 20.6666ZM19.6666 11.9999C19.6666 16.2341 16.2341 19.6666 11.9999 19.6666C7.76574 19.6666 4.33325 16.2341 4.33325 11.9999C4.33325 7.76574 7.76574 4.33325 11.9999 4.33325C16.2341 4.33325 19.6666 7.76574 19.6666 11.9999Z"
          fill="#06061B"
        />
        <path
          d="M11.7433 15.46C11.4204 15.4358 11.1033 15.3616 10.8033 15.24C10.5234 15.1221 10.2662 14.9562 10.0433 14.75C9.83252 14.5532 9.65979 14.3193 9.53374 14.06C9.40581 13.7947 9.33754 13.5045 9.33374 13.21H10.5633C10.5766 13.5367 10.6899 13.81 10.9033 14.03C11.1233 14.2433 11.4033 14.3667 11.7433 14.4V12.42L11.2733 12.35C10.7333 12.2767 10.3033 12.0733 9.98326 11.74C9.66993 11.4067 9.51326 10.9833 9.51326 10.47C9.51326 10.2033 9.57326 9.95333 9.69326 9.72C9.8136 9.4797 9.98029 9.26559 10.1837 9.09C10.3934 8.90137 10.6333 8.74926 10.8933 8.64C11.1643 8.52724 11.4509 8.45643 11.7433 8.43V7.5H12.4733V8.45C12.7733 8.49 13.0499 8.57 13.3033 8.69C13.5472 8.79708 13.7706 8.94599 13.9633 9.13C14.1499 9.31 14.2966 9.51667 14.4033 9.75C14.5166 9.98333 14.5799 10.2333 14.5937 10.5H13.3633C13.353 10.2671 13.2568 10.0462 13.0933 9.88C12.9285 9.69712 12.7124 9.56818 12.4733 9.51V11.34L12.8133 11.39C13.0614 11.4285 13.3034 11.4991 13.5333 11.6C13.7599 11.7 13.9571 11.83 14.1237 11.99C14.2966 12.15 14.4299 12.3433 14.5237 12.57C14.6237 12.79 14.6733 13.04 14.6733 13.32C14.6763 13.5939 14.6217 13.8654 14.5132 14.1169C14.4047 14.3684 14.2446 14.5943 14.0433 14.78C13.837 14.9684 13.6003 15.1205 13.3433 15.23C13.0658 15.3461 12.7726 15.4202 12.4733 15.45V16.48H11.7433V15.46ZM12.4733 14.39C12.7866 14.3367 13.0304 14.2233 13.2033 14.05C13.3766 13.87 13.4633 13.6433 13.4633 13.37C13.4633 13.1167 13.3799 12.93 13.2133 12.81C13.0456 12.6829 12.8503 12.5972 12.6433 12.56L12.4733 12.53V14.39ZM11.7433 9.48C11.4788 9.51328 11.2292 9.62075 11.0233 9.79C10.8299 9.95 10.7333 10.1667 10.7333 10.44C10.7333 10.6733 10.7966 10.85 10.9237 10.97C11.0571 11.0833 11.2299 11.1567 11.4433 11.19L11.7433 11.24V9.48Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14079_7258">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </>
  ),
}
